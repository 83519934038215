import axios from 'axios';
import { API_URL } from './constants';

const api = axios.create({
    baseURL: `${API_URL}`,
    headers: {
        'Content-Type': 'application/json',
    },
});
/**
 intercept any error responses from the api
 and check if the token is no longer valid.
 ie. Token has expired or user is no longer
 authenticated.
 logout the user if the token has expired
**/

api.interceptors.response.use(
    (res: any) => res,
    (err: any) => {
        try {
            if (err.response.status === 401) {
                const errorObject = {
                    message: err.response.data.detail,
                    code: 1
                };
                return Promise.reject(errorObject);
            } else if (err.response.status === 403 && err.response.data.detail === 'Access is denied') {
                const errorObject = {
                    message: err.response.data.detail,
                    code: 1
                };
                return Promise.reject(errorObject);
            } else if (err.response.status === 400) {
                let message = "Dữ liệu gửi lên không hợp lệ";
                try {
                    message = err.response.data.title;
                } catch (error) {
                }
                const errorObject = {
                    message: message,
                    code: 1
                };
                return Promise.reject(errorObject);
            } else {
                return err.response;
            }
        } catch (error) {
            const errorObject = {
                message: "Kiểm tra mạng",
                code: 1
            };
            return Promise.reject(errorObject);
        }
    }
);
export { api };