import { api } from "../helpers/api";

const shorLink = async (longUrl: string, jsonUserAgent: string, deviceId: string) => {
    const body = { longUrl, jsonUserAgent, deviceId };
    return await api.post('/api/v1/url/short-link', body).then((response) => {
        return response.data;
    });
};

const expandLink = async (shortCode: string, shortLink: string, jsonUserAgent: string, deviceId: string) => {
    const body = { shortCode, shortLink, jsonUserAgent, deviceId };
    return await api.post('/api/v1/url/expand-link', body).then((response) => {
        return response.data;
    });
};

export const shortLinkApis = {
    shorLink,
    expandLink
};