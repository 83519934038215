import { Layout } from 'antd'
import { Content } from 'antd/es/layout/layout'
import React from 'react'

export const LayoutPage = ({ children }: { children: React.ReactNode }) => {
    return (
        <Layout>
            <Content>
                <div>
                    {children}
                </div>
            </Content>
        </Layout>
    )
}
