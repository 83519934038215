import { message } from 'antd'
import React, { Fragment, useEffect, useState } from 'react'
import Fingerprint2 from 'fingerprintjs2';
import { shortLinkApis } from '../services/shortLinkApis';
import UAParser from 'ua-parser-js';
import Swal from 'sweetalert2';
import { copyToClipboard } from '../helpers/utility';

export const HomePage = () => {
    const [showBtnLoading, setShowBtnLoading] = useState(false);
    const [url, setUrl] = useState('');
    const [deviceId, setDeviceId] = useState('');
    const [userAgent, setUserAgent] = useState<any>('');

    useEffect(() => {
        const getInfoBrower = async () => {
            const fingerprint = await new Promise<string>((resolve) => {
                Fingerprint2.get({}, (components: any) => {
                    const values = components.map((component: any) => component.value);
                    const fingerprint = Fingerprint2.x64hash128(values.join(''), 31);
                    resolve(fingerprint);
                });
            });
            setDeviceId(fingerprint);
            const parser = new UAParser();
            const result = JSON.stringify(parser.getResult());
            setUserAgent(result);
        };
        getInfoBrower();
    }, []);

    const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        try {
            setShowBtnLoading(true);
            const response = await shortLinkApis.shorLink(url, userAgent, deviceId);
            if (response.statusCode === 200) {
                setUrl('');
                message.success({ content: response.message, duration: 10 })
                let urlReturn = '';
                if (!response.data.includes('http')) {
                    urlReturn = `https://${response.data}`;
                }
                Swal.fire({
                    title: `<strong>${urlReturn}</strong>`,
                    icon: "success",
                    showCloseButton: true,
                    showCancelButton: true,
                    focusConfirm: false,
                    allowOutsideClick: false,
                    confirmButtonText: 'Go',
                    cancelButtonText: `Coppy`,
                    confirmButtonColor: '#ff6363',
                    cancelButtonColor: '#3085d6'
                }).then((result) => {
                    if (result.isConfirmed) {
                        window.open(urlReturn, '_blank');
                        console.log("Confirmed");
                    } else if (result.dismiss === Swal.DismissReason.cancel) {
                        copyToClipboard(urlReturn)
                        console.log("Cancelled");
                    }
                });
            } else {
                message.error({ content: response.message, duration: 2 });
            }
        } catch (error: any) {
            message.error({ content: error.message as string, duration: 5 });
        } finally {
            setShowBtnLoading(false);
        }
    };

    return (
        <Fragment>
            <div className="Background">
                <div className="Header" style={{ backgroundColor: '#65dfee' }}>
                    <img src="/imgs/logo_w.png" alt=" Prune Logo" />
                </div>
                <div className="searchBar container-fluid">
                    <div className="searchBar_inside">
                        <form
                            onSubmit={onSubmit}>
                            <input type="text" required
                                value={url}
                                onChange={(e) => setUrl(e.target.value)}
                                pattern="(http(s)?:\/\/)?.+\..+" />
                            <div>
                                {showBtnLoading ?
                                    (
                                        <div className="loading">
                                            <div className="loader"> </div> <p className="loadingText">LOADING...</p>
                                        </div>
                                    )
                                    :
                                    (
                                        <button type="submit" name="button">
                                            <img src="/imgs/shears.svg" alt="Pruning Shears" />
                                            <span className="pruneMeText">Short Link</span>
                                        </button>
                                    )
                                }
                            </div>
                        </form>
                    </div>
                </div>
                {/* CLOUDS */}
                <div className="Large_cloud">
                    <img src="/imgs/big-cloud.png" alt="big cloud" />
                </div>
                <div className="Medium_cloud">
                    <img src="/imgs/medium-cloud.png" alt="medium cloud" />
                </div>
                <div className="Small_cloud1">
                    <img src="/imgs/small-cloud.png" alt="small cloud" />
                </div>
                <div className="Small_cloud2">
                    <img src="/imgs/small-cloud.png" alt="small cloud" />
                </div>
                <div className="Prune" />
            </div>
        </Fragment>
    )
}
