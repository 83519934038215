import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import { NotFoundPage } from './pages/NotFoundPage';
import { LayoutPage } from './pages/Layout';
import { HomePage } from './pages/Home';
import { LinkPayPage } from './pages/LinkPage';

function App() {
  return (
    <Router>
      <Routes>
        <Route path='/' element={
          <LayoutPage children={<HomePage />} />
        } />

        <Route path='/page-not-found' element={<NotFoundPage />} />

        <Route path='/:code' element={
          <LayoutPage children={<LinkPayPage />} />
        } />


        <Route path='*' element={<NotFoundPage />} />
      </Routes>
    </Router>
  );
}

export default App;
