import { Empty } from 'antd'


export const NotFoundPage = () => {
    return (
        <Empty
            image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
            imageStyle={{ height: '250px' }}
            style={{ minHeight: '100vh', }}
            description={
                <span>
                    Not Found Page
                </span>
            }>
        </Empty>
    )
}
