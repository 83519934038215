import { Spin } from 'antd';
import styled from 'styled-components';
import Fingerprint2 from 'fingerprintjs2';
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import UAParser from 'ua-parser-js';
import { shortLinkApis } from '../services/shortLinkApis';

export const LinkPayPage = () => {
    const { code } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        const initData = async () => {
            if (code) {
                const deviceId = await new Promise<string>((resolve) => {
                    Fingerprint2.get({}, (components: any) => {
                        const values = components.map((component: any) => component.value);
                        const fingerprint = Fingerprint2.x64hash128(values.join(''), 31);
                        resolve(fingerprint);
                    });
                });
                const parser = new UAParser();
                const jsonUserAgent = JSON.stringify(parser.getResult());
                const fullUrl = window.location.href;
                const response = await shortLinkApis.expandLink(code, fullUrl, jsonUserAgent, deviceId);

                if (response.statusCode === 200) {
                    window.location = response.data;
                } else {
                    navigate('/page-not-found')
                }
            }
        };
        initData();
    }, []);
    return (
        <LoadingPageStyled>
            <Spin />
        </LoadingPageStyled>
    )
}

const LoadingPageStyled = styled.div`
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content:center;
  z-index: 9999;
  .box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 95px 132px;
    gap: 16px;
    background: #FFFFFF;
    border-radius: 6px;
    p {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: #363565;
    }
  }
`;