import ClipboardJS from 'clipboard';

export const copyToClipboard = (textToCopy: string) => {
    const fakeButton = document.createElement('button');
    fakeButton.setAttribute('data-clipboard-text', textToCopy);

    const clipboard = new ClipboardJS(fakeButton);

    clipboard.on('success', () => {
        console.log('Text copied to clipboard');
        clipboard.destroy();
    });

    clipboard.on('error', () => {
        console.error('Unable to copy text to clipboard');
        clipboard.destroy();
    });

    fakeButton.click();
};
